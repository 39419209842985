<template>
  <div class="component-wrap top">
    <div class="title-box">
      <h2>
        {{ $t("mine.my_integral") }}
        <!-- 我的积分 -->
      </h2>
      <span class="desc">
        {{ $t("integral.tips") }}
        <!-- 用于记录您登录、分享、评论、资源学习等产生平台活跃度的积分奖励 -->
      </span>
    </div>
    <div class="source">
      <div class="source-item">
        <div class="label">
          {{ $t("integral.level_integral") }}
          <!-- 等级积分 -->
        </div>
        <div class="value">{{ staticInfo.sum }}</div>
      </div>
      <div class="source-item">
        <div class="label">
          {{ $t("integral.available_integral") }}
          <!-- 可用积分 -->
        </div>
        <div class="value">{{ staticInfo.usablesum }}</div>
      </div>
      <div class="source-item">
        <div class="label">
          {{ $t("integral.year_integral") }}
          <!-- 本年获得积分 -->
        </div>
        <div class="value">{{ staticInfo.year }}</div>
      </div>
      <div class="source-item">
        <div class="label">
          {{ $t("integral.month_integral") }}
          <!-- 本月获得积分 -->
        </div>
        <div class="value">{{ staticInfo.month }}</div>
      </div>
    </div>
  </div>
  <div class="component-wrap container">
    <div class="tabs">
      <div
        class="tabs-item"
        :class="activeIndex === index && 'active'"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="tabs-line"></div>
    <div class="tab-wrap" v-show="activeIndex == 0">
      <div class="search">
        <a-range-picker
          v-model:value="queryParam.date"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
        />
        <div class="search-btn">
          <a-button type="primary" ghost @click="handleSearch">
            {{ $t("XB_Search") }}
            <!-- 搜索 -->
          </a-button>
        </div>
      </div>
      <div class="list">
        <a-table
          :columns="columns"
          :data-source="state.dataSource"
          :rowKey="(record, index) => index"
          :bordered="false"
          :pagination="pagination"
          :loading="state.loading"
          @change="handleTableChange"
        >
          <template #tooltip="{ text }">
            <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
          </template>
          <template #time="{ text }">
            <span>{{ dateFormat(text) }}</span>
          </template>
        </a-table>
      </div>
    </div>
    <div class="tab-wrap" v-show="activeIndex == 1">
      <div class="component-tit">
        {{ $t("mine.score_rule_tip") }}
        <!-- 进行以下动作会获得积分奖励，在一个动作周期内你最多得到的奖励次数有限制。 -->
      </div>
      <div class="list">
        <a-table
          :columns="ruleColumns"
          :data-source="ruleList"
          rowKey="integralId"
          :bordered="false"
          :pagination="false"
        >
          <template #integralTimesTitle>
            {{ $t("integral.table.cycle") }}
            <!-- 动作周期 -->
            <a-tooltip :title="$t('integral.cycle_tip')">
              <!-- 一次性：该动作只需要完成1次，奖励1次。不限制：该动作可以完成多次，奖励多次。 -->
              <QuestionCircleOutlined style="color: #999" />
            </a-tooltip>
          </template>
          <template #integralTimes="{ record }">
            {{
              record.integralTimes == 1
                ? $t("integral.table.cycle_state", 1)
                : $t("integral.table.cycle_state", 2)
            }}
            <!-- "一次性" : "不限制" -->
          </template>
          <template #integralLimit="{ record }">
            {{
              record.integralTimes == 1
                ? "/"
                : record.integralLimit == 0
                ? $t("integral.table.cycle_state", 2)
                : // 不限制
                  record.integralLimit
            }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, toRaw } from "vue";
import { getStudyIntegralList, getStudyIntegralRule } from "@/api/mine";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
export default {
  components: {},
  setup() {
    const { t: $t } = useI18n();
    const tabList = reactive([$t("integral.all_record")]); // 全部积分记录
    const activeIndex = ref(0);
    const changeTab = (i) => {
      if (activeIndex.value === i) return;
      activeIndex.value = i;
    };

    const staticInfo = reactive({
      sum: 0,
      usablesum: 0,
      year: 0,
      month: 0,
    });

    const stateData = reactive({
      columns: [
        {
          title: $t("integral.table.action"),
          // title: "动作名称",
          dataIndex: "action",
          ellipsis: true,
          width: 200,
          slots: { customRender: "tooltip" },
        },
        {
          title: $t("integral.table.integral"),
          // title: "积分",
          width: 100,
          dataIndex: "integral",
          ellipsis: true,
        },
        {
          title: $t("mytime.table.time"),
          // title: "时间",
          dataIndex: "createTime",
          ellipsis: true,
          width: 200,
          slots: { customRender: "time" },
        },
        {
          title: $t("mytime.table.descr"),
          // title: "备注说明",
          dataIndex: "reason",
          ellipsis: true,
          slots: { customRender: "tooltip" },
        },
      ],
      ruleList: [],
      ruleColumns: [
        {
          title: $t("integral.table.action"),
          // title: "动作名称",
          dataIndex: "integralName",
          width: 120,
        },
        {
          dataIndex: "integralTimes",
          slots: {
            title: "integralTimesTitle",
            customRender: "integralTimes",
          },
          width: 120,
          align: "center",
        },
        {
          title: $t("integral.table.descr"),
          // title: "描述说明",
          dataIndex: "intro",
          width: 400,
        },
        {
          title: $t("integral.table.bonus_points"),
          // title: "奖励积分",
          dataIndex: "integral",
          slots: { customRender: "integral" },
          width: 120,
          align: "center",
        },
        {
          title: $t("integral.table.daily_limit"),
          // title: "每日积分上限",
          dataIndex: "integralLimit",
          slots: { customRender: "integralLimit" },
          width: 130,
          align: "center",
        },
      ],
    });
    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return getStudyIntegralList(form).then((res) => {
        staticInfo.sum = res.data.sum;
        staticInfo.usablesum = res.data.usablesum;
        staticInfo.year = res.data.year;
        staticInfo.month = res.data.month;
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };
    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      date: [],
    });
    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    getStudyIntegralRule().then((res) => {
      stateData.ruleList = res.data || [];
      if (stateData.ruleList.length)
        tabList.push($t("Lab_MyIntegral_IntegralRule"));
      // 积分规则
    });

    return {
      dateFormat,
      tabList,
      activeIndex,
      changeTab,
      handleSearch,
      staticInfo,
      ...toRefs(stateData),
      state,
      queryParam,
      handleTableChange,
      pagination,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .component-tit {
    font-size: 15px;
    line-height: 16px;
    color: #333;
    padding-left: 6px;
    margin: 0 0 10px;
    border-left: 4px solid @color-theme;
  }
}
.top {
  display: flex;
  flex-direction: column;
  height: 230px;
  box-sizing: border-box;
  background: #fff url("../../../assets/image/Intersect-bg.png") no-repeat;
  background-size: 52% 55%;
  background-position: top right;
  .title-box {
    flex: 1;
    // height: 130px;
    font-weight: bold;
    color: #333;
    h2 {
      font-size: 36px;
      font-style: oblique;
      font-weight: bold;
      line-height: 40px;
    }
    .desc {
      font-size: 16px;
    }
  }
  .source {
    flex: 1;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .source-item {
      flex: 1;
      position: relative;
      text-align: center;
      &::after {
        content: "";
        width: 2px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: #999;
      }
      &:last-child::after {
        background: transparent;
      }
      .label {
        height: 20px;
        line-height: 16px;
        font-size: 14px;
        color: #666;
      }
      .value {
        font-size: 40px;
        color: #333;
        font-weight: bold;
      }
    }
  }
}
.container {
  height: calc(100% - 175px);
  margin-top: 16px;
  padding: 0;
  .tabs {
    .mixinFlex(flex-start; center);
    padding: 30px 30px 0;
    &-item {
      font-size: 16px;
      margin: 0 10px;
      line-height: 1;
      position: relative;
      color: #999;
      padding: 4px 5px;
      cursor: pointer;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        bottom: -20px;
        left: 0;
        transition: all 0.2s;
      }
      &.active {
        color: var(--theme);
        &::after {
          background-color: var(--theme);
        }
      }
    }
  }
  .tabs-line {
    margin-top: 20px;
    border-bottom: 1px solid #eee;
  }
  .tab-wrap {
    padding: 20px 30px 30px;
    .search-btn {
      display: inline-block;
      margin-left: 12px;
    }
    .list {
      margin-top: 16px;
      ::v-deep(.ant-table-pagination.ant-pagination) {
        float: none;
        text-align: center;
      }
    }
  }
}
</style>
